import React, { useContext, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const LogoLink = loadable(() => import('../LogoLink'))
const Menu = loadable(() => import('../Menu/Menu'))

const Header = ({ isFrontPage, removeMainNav, removeCta, useAlternativeBranding, branding, utm }) => {
    const headerData = useStaticQuery(graphql`
        {
            allWpMenuItem(filter: { locations: { eq: PRIMARY } }) {
                nodes {
                    id
                    label
                    title
                    path
                    url
                    parentId
                    target
                    childItems {
                        nodes {
                            id
                            path
                            url
                            label
                        }
                    }
                }
            }
        }
    `)

    const [options] = useContext(OptionsContext)

    const [isExpanded, setToggleExpansion] = useState(false)

    const toggleMenu = () => {
        setToggleExpansion((prev) => !prev)
    }

    // Check to see if there are any menu items; if yes, filter out the top-level menu items – ELSE – return null
    const menu = headerData.allWpMenuItem.nodes
        ? headerData.allWpMenuItem.nodes.filter((menuItem) => menuItem.parentId === null)
        : null

    const journeyLink = {
        url: options.journey.mainJourney.link,
        title: 'Get Free Quotes'
    }

    const logoLink = <LogoLink useAlternativeBranding={useAlternativeBranding} branding={branding} />

    return (
        <header id="top" className="c-header">
            <div className="container-lg">
                <div className="c-header__inner">
                    <div className="c-header__mobile-aligner">
                        {isFrontPage ? (
                            <h1
                                className={`c-header__logo ${!useAlternativeBranding ? 'c-header-logo--standard' : ''}`}
                            >
                                {logoLink}
                            </h1>
                        ) : (
                            <div
                                className={`c-header__logo ${!useAlternativeBranding ? 'c-header-logo--standard' : ''}`}
                            >
                                {logoLink}
                            </div>
                        )}

                        {!removeMainNav && (
                            <button
                                className={`c-header-menu-toggle ${isExpanded ? 'is-open' : 'is-closed'}`}
                                onClick={toggleMenu}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        )}
                    </div>

                    <div className={`c-header__nav-container ${isExpanded ? 'is-open' : 'is-closed'}`}>
                        {!removeMainNav && <Menu data={menu} isExpanded={isExpanded} />}

                        {!removeCta && <ButtonLink data={journeyLink} className="c-header__button" utm={utm} />}
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    /**
     * Set whether the current page is the homepage or not (changes logo to either have an H1 or a span wrapped around it)
     */
    isFrontPage: PropTypes.bool,
    /**
     * Remove the main navigation and burger icon
     */
    removeMainNav: PropTypes.bool,
    /**
     * Remove CTA to lead gen journey
     */
    removeCta: PropTypes.bool,
    /**
     * Choose to replace the branding with something else
     */
    useAlternativeBranding: PropTypes.bool,
    /**
     * Branding to replace with
     */
    branding: PropTypes.oneOf(['insurance-hero']),
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Header.defaultProps = {
    isFrontPage: false,
    removeMainNav: false,
    removeCta: false,
    useAlternativeBranding: false
}

export default Header
